import client from "./client";

const createCategory = (data) => {
  return client
    .post("/category", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const getCategory = (id) => {
  return client
    .get(`/category/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const updateCategory = (id, data) => {
  return client
    .put(`/category/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const getCategories = (filter) => {
  return client
    .get(`/categories`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const deleteCategories = (data) => {
  return client
    .put(`/category`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


const categoryAPI = {
  getCategories,
  createCategory,
  getCategory,
  updateCategory,
  deleteCategories,
};

export default categoryAPI;
